/* Style for the login container */
.login-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

/* Style for the login section */
.login-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    margin: 5px 0;
}

.error-message {
    color: red;
}

/* Style for the new logo and login container */
.logo-and-login-container {
    display: flex;
    align-items: center;
    justify-content: center; 
    max-width: 600px; 
    height: 100vh;
    margin: 0 auto; 
}

/* Style for the logo container */
.logo-container {
    padding-right: 20px;
}

.login-container {
    flex: 1; 
}
