/* Shop Approval Container and Toggle Button */
.AdminShopApproval-home-container {
    display: flex;
    flex-direction: column;
}

.AdminShopApproval-toggle-button {
    align-self: flex-start;
    margin: 10px;
    z-index: 2;
    position: relative;
}

/* Side Menu */
.AdminShopApproval-side-menu {
    width: 0;
    overflow-x: hidden;
    position: fixed;
    background-color: #111;
    transition: 0.5s;
    padding-top: 60px;
    color: #818181;
    height: 92vh;
    z-index: 1;
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
}

.AdminShopApproval-side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.AdminShopApproval-side-menu ul li {
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

.AdminShopApproval-side-menu ul li:hover {
    color: #f1f1f1;
}

.AdminShopApproval-side-menu.active {
    width: 250px;
}

/* Content Container and Main Content */
.AdminShopApproval-content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: margin-left 0.5s ease-in-out;
    margin-left: 0;
}

.AdminShopApproval-main-content {
    text-align: center;
}

/* Profile Table */
.AdminShopApproval-profile-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border: 1px solid black;
}

.AdminShopApproval-table-header {
    background-color: lightgrey;
    padding: 10px;
    border: 1px solid black;
}

.AdminShopApproval-table-data {
    background-color: white;
    padding: 10px;
    border: 1px solid black;
}

/* Purple Logout Button */
.AdminShopApproval-purple-logout-button {
    background-color: rgb(99, 43, 99);
    color: white;
    border: none;
    padding: 10px 20px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    display: block; 
    margin: 20px auto 10px; 
}

/* Logo Placeholder */
.AdminShopApproval-logo-placeholder {
    margin-bottom: 5px;
}

.AdminShopApproval-left-align-text {
    text-align: left;
}

.AdminShopApproval-margin-bottom {
    margin-bottom: 4rem;
}

/* Toggle Button Style */
.AdminShopApproval-button-container {
    display: flex;
    justify-content: space-between;
    width: 120px;
}

.AdminShopApproval-toggle-button {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 1;
    border: none;
    background: none;
}

/* Store Container */
.AdminShopApproval-store-container {
    display: flex;
    flex-direction: column;
}

.AdminShopApproval-first-column {
    width: 120px;
}

/* Show User ID Button */
.AdminShopApproval-show-user-id-button {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

/* Add borders to the table, rows, and cells */
.AdminShopApproval-profile-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ffffff;
  }
  
  .AdminShopApproval-profile-table th,
  .AdminShopApproval-profile-table td {
    border: 1px solid #000000;
    padding: 8px;
    text-align: left;
  }
  
  /* Highlight table header row */
  .AdminShopApproval-profile-table th {
    background-color: #ffffff;
  }
  
  /* Add hover effect to table rows */
  .AdminShopApproval-profile-table tbody tr:hover {
    background-color: #cac2c2;
  }
