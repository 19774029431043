.store-container {
    display: flex;
    flex-direction: column;
}

/* Styles for Store Side Menu */
.store-side-menu {
    width: 0;
    overflow-x: hidden;
    position: fixed;
    background-color: #111;
    transition: 0.5s;
    padding-top: 60px;
    color: #818181;
    height: 92vh;
    z-index: 1;
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
}

.store-side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.store-side-menu ul li {
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

.store-side-menu ul li:hover {
    color: #f1f1f1;
}

.store-side-menu.active {
    width: 250px;
}

/* Styles for Store Content Container and Main Content */
.store-content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: margin-left 0.5s ease-in-out;
    margin-left: 0;
}

.store-main-content {
    text-align: center;
}

.store-logo-placeholder {
    margin-bottom: 5px;
}

/* Styles for Store Profile Table */
.store-profile-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border: 1px solid black;
}

.store-table-header {
    background-color: lightgrey;
    padding: 10px;
    width: 40%;
    border: 1px solid black;
}

.store-table-data {
    background-color: white;
    padding: 10px;
    border: 1px solid black;
}

/* Styles for Store Toggle Button */
.store-toggle-button {
    align-self: flex-start;
    margin: 10px;
    z-index: 2;
    position: relative;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background: none;
}

/* Additional styles for Store Logout Button */
.store-purple-logout-button {
    background-color: rgb(99, 43, 99);
    color: white;
    border: none;
    padding: 10px 20px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    display: block; 
    margin: 20px auto 10px;
}
