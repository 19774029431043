/* Style for the Signup container */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  /* Style for individual form sections */
  .form-section {
    margin: 3px 0;
  }
  
  /* Style for the password input container */
  .password-input-container {
    position: relative;
  }
  
  .password-toggle-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
  }
  
  .popup {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  
  .error-message {
    color: red;
  }
  
  .logo {
    cursor: pointer;
  }
  
  .signup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .email-exists-message {
    color: red;
  }
  
  .success-message-container {
    text-align: center;
  }
  
  .role-select {
    width: 100%;
    padding: 5px;
  }
  